<template>
    <div>
        <EKTable
            :items="accountList"
            :columns="columns"
            @details="openAccountsDetails"
            @delete-selected="fireDeleteEvent"
        >
            <template slot="items.dateCreated" scope="{value}">
                {{new Date(value).toLocaleDateString('en-GB')}}
            </template>
            
        </EKTable>
    </div>
</template>
<script>
import EKTable from "@Ekcore/components/EK-table";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
    components: {
        EKTable,
    },
    data: () => ({
        columns: [
            {
                label: "الاسم الكامل",
                field: "fullName"
            },
            {
                label: "اسم المستخدم",
                field: "username"
            },
            {
                label: "تاريخ الإضافة",
                field: "dateCreated"
            },
            {
                label: "تفاصيل",
                field: "details",
                sortable: false
            }

        ],
    }),
    computed: {
        ...mapState({
            accountDto: state => state.accounts.accountDto,
            // accountList: state => state.accounts.accountList,
        }),
        ...mapGetters(['accountList'])
    },
    created() {
       this.getAccountList();
    },
    methods: {
        ...mapActions([
            "getAccountList",
            "deleteAccount",
            "deleteAccountList"
        ]),
        ...mapMutations(["Update_Account", "Reset_Account_Dto"]),
        fireDeleteEvent(list) {
            this.deleteAccountList(list)
        },
        openAccountsDetails(p) {
            this.$router.push(`/admin/accounts/${p.row.id}`)
        }
    },
    beforeDestroy() {
        this.$store.commit("Reset_Search_Dto");
    }
};
</script>
